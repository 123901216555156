.mt-report .ct-chart {
  margin-top: 1em;
}

.mt-report-expense-income .ct-series-a .ct-bar {
  stroke: #7cb342;
}

.mt-report-expense-income .ct-series-b .ct-bar {
  stroke: #f44336;
}

.mt-report-expense-income .ct-bar {
  stroke-width: 1em;
  stroke-opacity: 0.75;
}

.mt-report-expense-tags .ct-bar {
  stroke: #f44336;
  stroke-width: 1em;
  stroke-opacity: 0.75;
}

.mt-report-net-worth .ct-point,
.mt-report-net-worth .ct-line {
  stroke: #7cb342;
}

.mt-report-net-worth .ct-area {
  fill: #7cb342;
}

.chartist-tooltip {
  background: #d4d4d5;
  border-radius: 3px;
}

.chartist-tooltip:before {
  border-top-color: #d4d4d5;
}

.mt-report-stats {
  padding: 0.75em;
  // background: #f8f8f8;
  border-bottom: solid 1px;
  border-bottom-color: #d4d4d5;
}

@media (max-width: 420px) {
  .ui.statistic {
    width: 100%;
  }

  .ui.statistic+.ui.statistic {
    margin: 0 1.5em 1em;
  }
}