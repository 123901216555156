.ui.form.account-form .input-right .mono {
  display: block;
  margin-top: 0.75em;
}

.ui.form.account-form .field.checkbox {
  padding-top: 0.75em;
}

.ui.form.account-form .fields.no-margin {
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 767px){

  .ui.form .fields{
    margin: 0 -0.5em 1em;
  }
}
