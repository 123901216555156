.account-detail {
  padding: 0.75em;
  background: #f9fafb;
  border-bottom: solid 1px #d4d4d5;
  box-shadow: 0 1px 1px 0 rgba(34, 36, 38, 0.15) inset;
}

.account-detail .account-detail-name{
  font-weight: 700;
}

.account-detail .account-detail-state{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.account-detail .account-detail-balance{
  padding-right: 10px;
}