.report-item-list__empty {
  padding: 1em;
}

.report-item-list-group {
  border-top: solid 1px #d4d4d5;
}

.report-item-list-group__header{
  font-weight: 600;
  background-color: rgba($color: #9e9e9e, $alpha: 0.05);
}

.report-item-list-group__name{
  display: table-cell;
  font-weight: 500;
  background-color: rgba($color: #2e2e2e, $alpha: 0.05);
  padding: 0.3em 1em;
}

@media (min-width: 768px) {
  .report-item-list-group:first-child {
    border-top: none;
  }
}

.report-item-list-item {
  width: 100%;
  border-top: solid 1px #d4d4d5;
  display: table;
  flex-flow: row nowrap;
  align-items: center;
}

.report-item-list-item__name, 
.report-item-list-item__column{
  display: table-cell;
  padding: 0.3em 1em;
}

.report-item-list-item__name {
  flex-basis: 100%;
  white-space: nowrap;
}

.report-item-list-item__column {
  text-align: right;
  white-space: nowrap;
  // flex: 1;
  // flex-flow: column nowrap;
}



