nav {
	position: fixed;
	top: 60px;
	width: 125px;
	min-height: 100%;
	transition: transform 0.5s ease;
	z-index: 101;

	.menu {
		min-height: 100vh !important;
		border: none !important;
		border-radius: 0 !important;

		.item {

			&:last-child {
				border-radius: 0 !important;
				border-bottom: solid 1px rgba(0, 0, 0, 0.15) !important;
			}
		}
	}
}
nav.open {
	transform: translate(0, 0);
}
nav.closed {
	transform: translate(-125px, 0);
}
.ui.menu {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	
  .blue.active.item {
		color: #3f51b5 !important;
		border-color: rgba(0, 0, 0, 0.15) !important;
	}
	
  .active.item {
		box-shadow: inset 0 1px 4px 0 rgba(34, 36, 38, 0.12) !important;
	}
}
.ui.blue.menu {
	.active.item {
		color: #3f51b5 !important;
		border-color: rgba(0, 0, 0, 0.15) !important;
	}
}
@media (min-width: 1194px) {
	nav {
		box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15), 0 2px 18px 0 rgba(34, 36, 38, 0.17);
	}
}
