body {
  height: auto !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu',"Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media (min-width: 768px) {
  body {
    background-color: #f5f5f5;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Ubuntu','Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
}

.mono {
  font-family: 'Ubuntu', 'Roboto Mono', monospace;
  color: #686869;
}

.positive {
  color: #02b60e;
}

.negative {
  color: #db2828;
}

.input-right {
  text-align: right;
}

.input-right input {
  text-align: right !important;
  font-family: 'Ubuntu','Roboto Mono', monospace !important;
}

// ----- container-full-page -----
.container-full-page{
  display: block;
  background-color: white;
  font-size: 1.14285714rem;
  line-height: 1.5;
}


@media (min-width: 768px) and (max-width: 1193px) {
  html,
  body {
    background-color: white;
  }

  .container-full-page {
    padding: 0 1em 1em 1em;
    margin-left: 3px;
  }

  .ui.warning {
    margin: 1em 1em 0 !important;
  }
}

@media (min-width: 1194px) {
  .container-full-page {
    max-width: 1060px;
    margin: 1em auto;
    padding: 0 1em 1em 1em;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}


.container-full-page.flat {
  padding: 0;
}

@media (max-width: 767px) {
  .ui.grid {
    margin: 0;
  }

  .ui.grid .row .column {
    padding: 0;
  }

  .container-raised-desktop .form-submit {
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    padding: 1rem;
  }

  .container-full-page .ui.grid .row {
    padding: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }

  .ui.warning {
    margin: 0 !important;
    box-shadow: none !important;
    padding: 1em !important;
  }

  .mt-settings .section__body {
    padding: 1em;
    border-top: solid 1px #d4d4d5;
  }
}


.ui.form .field > label {
  font-weight: 500;
}

.container-full-page .container-header {
  padding: 1em;
  border-bottom: solid 1px #d4d4d5;
  border-radius: 5px 5px 0 0;
  background: #f9fafb;
}

.container-full-page .container-footer > .ui.dropdown {
  margin-bottom: 1em;
}

.container-full-page .container-footer > .ui.dropdown:last-child {
  margin-bottom: 0;
}

.container-full-page .container-footer {
  padding: 1em;
  border-top: solid 1px #d4d4d5;
  border-radius: 0 0 5px 5px;
  background: #f9fafb;
}

.container-full-page .container-header > .ui.basic.buttons {
  background: #ffffff;
}

.container-full-page .report-table > .ui.table {
  margin: 0;
  border: none;
}

.container-full-page .report-table > .ui.table td,
.container-full-page .report-table >.ui.table thead th{
  padding: 0.28571429em 0.78571429em;
  
}

@media (max-width: 767px) {
  .container-full-page .container-header {
    padding: 0;
    background: #ffffff;
    border-radius: 0;
    border-top: solid 1px #d4d4d5;
  }

  .container-full-page .container-header > .ui.basic.buttons {
    border: none;
    border-radius: 0;
    border-right: solid 1px #d4d4d5;
    border-top: solid 1px #d4d4d5;
  }
}


// ----- container-raised-desktop -----
.container-raised-desktop {
  display: block;
  background-color: white;
  padding: 1em 0 0 0;
  max-width: 700px;
  font-size: 1.14285714rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .container {
    max-width: calc(100% - 125px);
    margin-left: 125px;
    margin-top: 1em;
  }

  .container-raised-desktop {
    margin: 1em auto;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}

.container-raised-desktop .ui.header,
.container-raised-desktop .ui.form,
.container-raised-desktop p {
  margin: 1rem;
}

.container-raised-desktop .form-submit {
  padding: 1em 0 0 0;
  text-align: right;
}

.container-raised-desktop .form-submit .button {
  margin: 0;
}

