.account-widget-group {
  border-top: solid 1px #d4d4d5;
}

.account-widget__new {
  padding-top: 1em;
  text-align: right;
}

.account-widget__new .ui.button {
  margin: 0;
}

@media (min-width: 768px) {
  .account-widget {
    border: solid 1px #d4d4d5;
    border-radius: 0.28571429rem;
  }

  .accounts-list-wrapper .account-widget {
    border: none;
  }

  .account-widget-group:first-child {
    border-top: none;
  }
}

.account-widget-group__header {
  display: table;
  width: 100%;
  cursor: pointer;
}

.account-widget-group__name,
.account-widget-group__total {
  display: table-cell;
  font-weight: 500;
  background-color: rgba(77, 78, 80, 0.05);
  padding: 0.5em 1em;
}

@media (max-width: 767px) {
  .accounts-list-wrapper .account-widget-group:first-child {
    border-top: none;
  }

  .account-widget-group__name,
  .account-widget-group__total {
    padding: 0.75em 1em;
  }

  .account-widget__new {
    border-top: solid 1px #d4d4d5;
    padding: 1em 0.75em;
  }
}

.account-widget-group__total {
  width: 1px;
  white-space: nowrap;
}

.account-widget-account {
  width: 100%;
  border-top: solid 1px #d4d4d5;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.account-widget-account__name,
.account-widget-account__balance {
  display: flex;
  padding: 0.5em 1em;
}

.account-widget-account__name {
  flex-basis: 100%;
  white-space: nowrap;
}

.account-widget-account__balance {
  text-align: right;
  white-space: nowrap;
  flex: 1;
  flex-flow: column nowrap;
}

.account-widget-account__name {
  padding: 0.5em 0.5em 0.5em 1em;
}

.account-widget-account__edit {
  flex: 1;
  padding: 0.75em 0.75em 0.75em 0;
}
